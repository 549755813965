<template>
  <div>
    <transition name="fade">
      <vue-internet-checker @status="offline" />
    </transition>

    <b-notification
      :active="offlineStatus"
      v-bind:class="[!offlineStatus ? 'd-none' : 'd-block']"
      :closable="false"
      style="height: 65px; padding: 10px; position: fixed; left: 0px; right: 0px; bottom: 0px; border-radius: 0px; display: flex; align-items: center;"
      type="is-danger"
      has-icon
      icon-pack="fa"
      role="alert"
    >
      <div style="height: 45px;" class="d-flex align-items-center">You are offline</div>
    </b-notification>
  </div>
</template>

<script>
import vueInternetChecker from 'vue-internet-checker'

export default {
  components: {
    vueInternetChecker
  },
  data() {
    return {
      offlineStatus: null
    }
  },
  methods: {
    offline(e) {
      this.offlineStatus = !e
    }
  },
}
</script>